import './owl.carousel';

/**
 * Carousels
 */
(function ($) {
  
  // Gallery
  $('#products-carousel').owlCarousel({
    autoplay: true,
    loop: true,
    margin: 20,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      1200: {
        items: 3,
      },
      1400: {
        items: 4,
      },
    },
  });

})(jQuery);
