/**
 * Ticket Form v1.0.0
 */

(function ($) {

  // contact Form Page
  var ticketForm = $('#ticket-form');
  var ticketFormSubmitButton = ticketForm.find(':submit');

  // jQuery AJAX form
  var cf = ticketForm.ajaxForm({
    beforeSubmit: function (arr, $form, options) {
      var filled = arr.every(function (input) {
        return input.value !== '';
      });

      if (filled) {
        ticketFormSubmitButton.attr('disabled', true);
        ticketFormSubmitButton.html('<div class="spinner-border spinner-border-sm text-white">');
      }
    },

    success: function (response) {
      // Reset submit button
      ticketFormSubmitButton.html('Send').attr('disabled', false);

      // If success
      if (response.success === true) {
        $('#alert-submission', ticketForm).show();
        setTimeout(function () { $('#alert-submission', ticketForm).hide() }, 5000);

        cf.clearForm();
        document.getElementById('alert-submission', ticketForm).scrollIntoView();

        // Add conversion tag here.
      }

      if (response.success === false && response.data.name) {
        $('input#name', ticketForm).addClass('is-invalid');
        $('#alert-name', ticketForm).text(response.data.name);

        $('#alert-name', ticketForm).show();
        setTimeout(function () {
          $('input#name', ticketForm).removeClass('is-invalid');
          $('#alert-name', ticketForm).text('');
          $('#alert-name', ticketForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.email) {
        $('input#email', ticketForm).addClass('is-invalid');
        $('#alert-email', ticketForm).text(response.data.email);

        $('#alert-email', ticketForm).show();
        setTimeout(function () {
          $('input#email', ticketForm).removeClass('is-invalid');
          $('#alert-email', ticketForm).text('');
          $('#alert-email', ticketForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.phone) {
        $('input#phone', ticketForm).addClass('is-invalid');
        $('#alert-phone', ticketForm).text(response.data.phone);

        $('#alert-phone', ticketForm).show();
        setTimeout(function () {
          $('input#phone', ticketForm).removeClass('is-invalid');
          $('#alert-phone', ticketForm).text('');
          $('#alert-phone', ticketForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.captcha) {
        $('input#captcha', ticketForm).addClass('is-invalid');
        $('#alert-captcha', ticketForm).text(response.data.captcha);

        $('#alert-captcha', ticketForm).show();
        setTimeout(function () {
          $('input#captcha', ticketForm).removeClass('is-invalid');
          $('#alert-captcha', ticketForm).text('');
          $('#alert-captcha', ticketForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.content) {
        $('textarea#content', ticketForm).addClass('is-invalid');
        $('#alert-content', ticketForm).text(response.data.content);

        $('#alert-content', ticketForm).show();
        setTimeout(function () {
          $('textarea#content', ticketForm).removeClass('is-invalid');
          $('#alert-content', ticketForm).text('');
          $('#alert-content', ticketForm).hide();
        }, 5000);
      }
    },

    error: function (response) {
      console.error(response);

      // Reset submit button
      ticketFormSubmitButton.html('Send').attr('disabled', false);

      $('#alert-submission-failed', ticketForm).show();
      setTimeout(function () { $('#alert-submission-failed', ticketForm).hide() }, 5000);

      document.getElementById('alert-submission-failed', ticketForm).scrollIntoView();
    },
  });

})(jQuery);
